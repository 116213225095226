  import React from 'react'
  import Layout from '../../../components/App/Layout'
  import Navbar from '../../../components/App/NavbarRV'
  import Footer from '../../../components/App/FooterRV'
  import KDPImagePage from '../../../assets/images/pages/3-1-0-0.jpg'
  
  const Details = () => {
      return (
          <Layout>
              <Navbar />
              <div>
                  <div className='container'>
                      <div className='page-title-content'>
                          <ul>
<li><a href='/'>Accueil</a></li><li><a href='/paca/'>Trouvez un expert près de chez vous</a></li><li>Expert batiment Provence-Alpes-Côte d'Azur</li>
                          </ul>
                      </div>
                  </div>
              </div>
              <section className="services-details-area ptb-100">
                  <div className="container">
                      <div className="row">
                          <div className="col-lg-8 col-md-12">
                              <div className="services-details-desc">
                                
<h1>Le cabinet RV expertises, votre Expert spécialisé en pathologies de la construction</h1>

<p>Le cabinet RV Expertises est un cabinet d’expertise à taille humaine, spécialisé dans les pathologies de la construction et l’accompagnement de l’assuré en expertise post-sinistre catastrophe naturelle type sécheresse, inondation et mouvements de terrains.</p>
<div className='services-details-image'>
  <img src={KDPImagePage} alt="Expert batiment Provence-Alpes-Côte d'Azur" />
</div>

<p>Nos experts et ingénieurs sapiteurs vous accompagnent afin de mieux vous orienter lors d’une acquisition d’un bien ou sa vente, l’apparition de fissures sur votre bâtiment, des malfaçons, humidité</p>
<p>Nous sommes à votre écoute afin de répondre au mieux à vos attentes</p>
<h2>Les objectifs d’une mission d’expertise bâtiment</h2>

<p>Un Expert technique bâtiment (et/ou Expert construction) a pour mission d’évaluer les dommages affectant votre ouvrage. </p>
<p>Il détermine les causes et les origines des désordres et vous oriente sur les travaux les plus adaptés afin de recouvrer un ouvrage structurellement sain et pérenne.</p>
<p>Il accompagne également les assurés lors d’opérations de contre-expertise amiable ou en contexte judiciaire afin de <u>défendre les intérêts de l’Assuré</u></p>

<h2>Nos expertises bâtiment </h2>
<p>Le cabinet RV Expert intervient en assistance technique pour le compte des assurés, des particuliers et des entreprises sur les thématiques suivantes :</p>
<ul><li>expertise de partie : pathologies de la construction,</li>
<li>expertise post sinistre catastrophe naturelle : sécheresse, inondation, mouvement de terrain, en accompagnement technique de l’assuré,</li>
<li>assistance en expertise judiciaire : rapport technique de contre-expertise et accompagnement de l’assuré dans les phases d’opérations d’expertises judiciaires</li></ul>

<h2>Départements d’intervention du cabinet RV Expertises en région PACA</h2>
<ul><li>département Bouches-du-Rhône (13)</li>
<li>Vaucluse (84)</li>
<li>Var (83)</li>
<li>Alpes maritimes (06)</li>
<li>Hautes Alpes (05)</li>
<li>Alpes de Haute-Provence (84)</li></ul>

<h2>Villes d’intervention du cabinet RV Expertises en région PACA</h2>
<ul>
<li>Marseille (13)</li>
<li>Aix-en-Provence (13) et Pays d’Aix,</li>
<li>Aubagne (13)</li>
<li>Salon de Provence</li>
<li>Arles</li>
<li>Martigues,</li>
<li>Istres,</li>
<li>Marignane,</li>
<li>Vitrolles,</li>
<li>Port de Bouc</li>
<li>Les Pennes Mirabeau</li></ul>


<div className="others-option d-flex align-items-center">
    <div className="option-item">
        <a class="default-btn" href="/contact/"><i className="flaticon-right"></i> Indiquez nous votre problématique construction</a>
    </div>
</div>
                                
                              </div>
                          </div>
                          <div className="col-lg-4 col-md-12">
                              <div className="services-details-info">
                                  <ul className="services-list">
                                      
<li><a href='/paca/expert-batiment-provence-alpes-cote-azur/' className='active'>Expert batiment Provence-Alpes-Côte d'Azur</a></li>
                                      
                                  </ul>
                                  <a href='javascript:history.go(-1)'>Retour</a>
                              </div>
                          </div>
                      </div>
                  </div>
              </section>
              <Footer />
          </Layout>
      );
  }

  export default Details